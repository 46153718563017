import axios from "axios";
// const JWTToken = `Bearer ${getToken()}`;

// eslint-disable-next-line no-unused-vars
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HASURA_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: getToken ? JWTToken : null,
  },
});
// eslint-disable-next-line no-unused-vars
const apiBackend = axios.create({
  baseURL: process.env.VUE_APP_SERVER_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: getToken ? JWTToken : null,
  },
});

export default {
  downloadZip(id) {
    return apiBackend.get("/download", {
      params: {
        id,
      },
    });
  },

  fetchApplicants() {
    return apiClient.get("/applicants");
  },

  getServerEndpoint() {
    return process.env.VUE_APP_HASURA_ENDPOINT;
  },

  login(email, password) {
    return apiClient.post("/login", { email, password });
  },
};
