<template>
  <div class="flex flex-col w-1/6 mx-auto mt-10">
    <p class="text-4xl m-3">Login</p>
    <input
      :disabled="getIsLoggingIn"
      v-model="email"
      type="text"
      placeholder="Email"
      class="p-3 text-xl border border-black rounded-md mb-3"
    />
    <input
      :disabled="getIsLoggingIn"
      v-model="password"
      type="password"
      placeholder="Password"
      class="p-3 text-xl border border-black rounded-md mb-3"
    />
    <button
      :disabled="getIsLoggingIn"
      class="border p-2 px-4 mx-auto rounded-md text-black border-black hover:bg-slate-800 hover:text-white transition-all"
      @click="userLogin"
    >
      {{ getIsLoggingIn ? "Verifying" : "Submit" }}
    </button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters(["getIsLoggingIn"]),
  },

  methods: {
    ...mapActions(["login"]),
    userLogin() {
      this.login({ email: this.email, password: this.password });
    },
  },

  watch: {
    getIsLoggingIn(logginngIn) {
      if (!logginngIn) {
        this.password = "";
        this.email = "";
      }
    },
  },
};
</script>
