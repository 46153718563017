<template>
  <div class="p-5">
    <h1 class="underline text-3xl">Applicants</h1>
    <select
      aria-placeholder="Select"
      class="border p-2 m-2 rounded text-gray-600"
      :class="getIsApplicantsLoading ? 'cursor-not-allowed' : ''"
      :disabled="getIsApplicantsLoading"
      v-model="type"
    >
      <option disabled class="text-gray-400">Select Type</option>
      <option class="text-gray-600">Not Downloaded</option>
      <option class="text-gray-600">Downloaded</option>
      <option class="text-gray-600">Modified</option>
    </select>

    <div class="flex justify-between">
      <button
        class="border border-red-500 rounded px-4 my-2 font-bold text-red-500 hover:bg-red-500 hover:text-white transition-all"
        @click="logout"
      >
        Log Out
      </button>
      <input
        :disabled="type == 'Select Type'"
        type="text"
        placeholder="Search"
        class="border p-2 m-2 rounded bg-gray-800 border-gray-700 text-white"
        :class="type == 'Select Type' ? 'bg-gray-500 border-gray-500' : ''"
        v-model="searchValue"
      />
    </div>
    <table class="w-full text-sm text-left text-gray-100">
      <thead class="text-xs text-gray-400 uppercase bg-gray-700">
        <tr>
          <th scope="col" class="px-6 py-3">Name</th>
          <th scope="col" class="px-6 py-3">Phone Number</th>
          <th scope="col" class="px-6 py-3">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="border-b bg-gray-800 border-gray-700 text-gray-300"
          v-for="applicant in searchedApplicants"
          :key="applicant.id"
        >
          <td class="px-6 py-4">{{ applicant.name }}</td>
          <td class="px-6 py-4">{{ applicant.phone_number }}</td>
          <td class="px-6 py-4">
            <a
              target="_blank"
              @click="download(applicant.id)"
              class="border-2 rounded px-3 py-2 border-green-800 hover:bg-green-800"
              :href="serverEndpoint + '/download?id=' + applicant.id"
            >
              Download
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      type: "Select Type",
      searchValue: "",
      serverEndpoint: process.env.VUE_APP_SERVER_ENDPOINT,
      applicants: [],
      searchedApplicants: [],
    };
  },
  computed: {
    ...mapGetters([
      "getApplicants",
      "getNotDownloadedApplicants",
      "getDownloadedApplicants",
      "getModifiedApplicants",
      "getIsApplicantsLoading",
    ]),
  },
  methods: {
    ...mapActions(["fetchApplicants", "markAsDownloaded", "fetchToken", "logout"]),
    download(id) {
      this.markAsDownloaded(id);
    },
  },
  created() {
    this.fetchToken();
    this.fetchApplicants();
  },

  watch: {
    type(newValue) {
      if (newValue == "Not Downloaded") {
        this.applicants = this.getNotDownloadedApplicants;
      } else if (newValue == "Downloaded") {
        this.applicants = this.getDownloadedApplicants;
      } else if (newValue == "Modified") {
        this.applicants = this.getModifiedApplicants;
      }
      this.searchValue = "";
    },
    getNotDownloadedApplicants() {
      if (this.type === "Not Downloaded") this.applicants = this.getNotDownloadedApplicants;
    },
    getDownloadedApplicants() {
      if (this.type === "Downloaded") this.applicants = this.getDownloadedApplicants;
    },
    getModifiedApplicants() {
      if (this.type === "Modified") this.applicants = this.getModifiedApplicants;
    },
    applicants(value) {
      this.searchedApplicants = value;
    },

    searchValue(value) {
      this.applicants.forEach((element) => {
        if (!element.name) console.log(element);
      });
      this.searchedApplicants = this.applicants.filter(
        (applicant) => applicant.name?.toLowerCase().includes(value) || applicant.phone_number.includes(value)
      );
    },
  },
};
</script>
