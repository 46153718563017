import Vuex from "vuex";

import applicants from "./modules/applicants";
import auth from "./modules/auth";

export default new Vuex.Store({
  modules: {
    applicants,
    auth,
  },
});
