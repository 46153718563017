// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import ApiClient from "../../utility/api.js";

const state = {
  applicants: [],
  isApplicantsLoading: false,
};
const getters = {
  getIsApplicantsLoading: (state) => state.isApplicantsLoading,
  getApplicants: (state) => state.applicants,
  getNotDownloadedApplicants: (state) => state.applicants.filter((applicant) => !applicant.is_downloaded),
  getModifiedApplicants: (state) => state.applicants.filter((applicant) => applicant.is_updated_since_downloaded),
  getDownloadedApplicants: (state) =>
    state.applicants.filter((applicant) => !applicant.is_updated_since_downloaded && applicant.is_downloaded),
};

const actions = {
  fetchApplicants: async ({ commit }) => {
    let result;
    commit("SET_APPLICANTS_IS_LOADING", true);
    try {
      result = await ApiClient.fetchApplicants();
    } catch (error) {
      commit("SET_APPLICANTS_IS_LOADING", false);
      return;
    }

    commit("SET_APPLICANTS", result.data.applicants);
    commit("SET_APPLICANTS_IS_LOADING", false);
  },

  markAsDownloaded: async ({ commit }, id) => {
    commit("SET_AS_DOWNLOADED", id);
  },

  getBackendUrl: () => {
    return ApiClient.getServerEndpoint();
  },
};

const mutations = {
  SET_APPLICANTS: (state, payload) => {
    for (let i = 0; i < payload.length; i++) {
      payload[i].phone_number = "0" + payload[i].phone_number;
    }
    state.applicants = payload;
  },
  SET_APPLICANTS_IS_LOADING: (state, payload) => {
    state.isApplicantsLoading = payload;
  },

  SET_AS_DOWNLOADED: (state, id) => {
    let index = _.findIndex(state.applicants, { id });
    let applicant = state.applicants.splice(index, 1)[0];
    applicant.is_downloaded = true;
    applicant.is_updated_since_downloaded = false;

    state.applicants.push(applicant);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
