import ApiClient from "../../utility/api.js";
import router from "@/router/index.js";

const state = {
  token: "",
  isLoggingIn: false,
};

const getters = {
  getToken: (state) => state.token,
  getIsLoggingIn: (state) => state.isLoggingIn,
  loggedIn: (state) => state.token != undefined || state.token != "",
};

const actions = {
  login: async ({ commit }, payload) => {
    commit("SET_IS_LOGGING_IN", true);
    let { email, password } = payload;
    let result;
    try {
      result = await ApiClient.login(email, password);
    } catch (error) {
      commit("SET_IS_LOGGING_IN", false);
      alert("Email or Password Incorrect");
      return;
    }
    localStorage.setItem("token", result.data.login.token);
    commit("SAVE_TOKEN", result.data.login.token);
    commit("SET_IS_LOGGING_IN", false);
    router.push({ name: "home" });
  },
  logout: ({ commit }) => {
    console.log("laskjflk");
    localStorage.removeItem("token");
    commit("SAVE_TOKEN", null);
    router.push({ name: "login" });
  },
  fetchToken: async ({ commit }) => {
    let token = localStorage.getItem("token");
    if (!token || token == "") {
      router.push({ name: "login" });
    }
    commit("SAVE_TOKEN", token);
  },
};

const mutations = {
  SAVE_TOKEN: (state, payload) => {
    state.token = payload;
  },
  SET_IS_LOGGING_IN: (state, payload) => {
    state.isLoggingIn = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
